<template>
  <v-row
      align="center"
      justify="center"
  >
    <v-col
        cols="12"
        md="6"
        sm="8"
    >
      <v-alert v-if="errorMessage" text type="error">
        {{ errorMessage }}
      </v-alert>

      <div v-if="!this.werkOrder">
        <div v-if="resultaat">
          <div v-if="resultaat.werk">
            <v-card v-for="werk in resultaat.werk" :key="werk.ordernr" class="mb-3">
              <v-card-title>{{ werk.plaats }}: {{ werk.omschrijving }}</v-card-title>
              <v-card-subtitle>Montage uren: {{ werk.montageUren }}. Starten in periode {{ werk.startenVanaf }} t/m
                {{ werk.startenUiterlijk }}
              </v-card-subtitle>
              <v-card-text>
                Order: {{ werk.ordernr }}
                <span v-if="werk.bijzonderheden"><br>Bijzonderheden: {{ werk.bijzonderheden }}</span>
                <br><br>
                <span v-if="!werk.reactieGegeven">
                  <v-btn color="success" @click="welInteresse(werk)">Ja, interesse!</v-btn>&nbsp;
                  <v-btn color="error" @click="geenInteresse(werk)">Nee, bedankt</v-btn>
                </span>
                <span v-else>
                  <span v-if="werk.interesse">
                    Reactie gegeven: uurtarief &euro; {{ werk.reactieTarief }}, startdatum
                    {{ werk.reactieStartdatum }}<br>
                  </span>
                  <span v-else>
                    Geen interesse<br>
                  </span>
                  <span v-if="werk.gegund">
                    <v-icon>mdi-thumb-up</v-icon> Je hebt de opdracht gekregen!
                  </span>
                  <span v-else>
                    <v-btn @click="wijzigReactie(werk)">Wijzig reactie</v-btn>
                  </span>
                </span>
              </v-card-text>
            </v-card>
          </div>
          <div v-else>
            Er is geen openstaand werk gevonden
          </div>
        </div>
      </div>
      <div v-else>
        <ReactieForm v-bind:werkOrder="this.werkOrder"/>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import ReactieForm from "@/components/ReactieForm";

export default {
  name: "Werk",
  components: {ReactieForm},

  data: function () {
    return {
      errorMessage: null,
      resultaat: null,
      updateTime: null,
      timeHidden: null,
      werkOrder: null,
    }
  },

  created: function () {
    this.updateContent()

    document.addEventListener('visibilitychange', () => {
      let state = document.visibilityState;
      if (state === 'hidden') {
        this.timeHidden = (new Date()).getTime()
      } else if (state === 'visible' && this.timeHidden) {
        let lastSec = parseInt(this.timeHidden / 1000)
        let nowSec = parseInt((new Date()).getTime() / 1000)
        if ((nowSec - lastSec) > 60) {
          this.updateContent()
        }
      }
    })
  },

  methods: {
    async updateContent() {
      axios({url: 'werk', method: 'GET'})
          .then(resp => {
            if (resp.data.error) {
              this.errorMessage = resp.data.error
              if (this.errorMessage === 'Authorization required') {
                this.$store.dispatch('logout')
                    .then(() => {
                      this.$router.push('/inloggen')
                    })
              }
            } else {
              this.resultaat = resp.data
              this.errorMessage = null
              let d = new Date();
              this.updateTime = d.toLocaleTimeString([], {timeStyle: 'short'})
            }
          })
          .catch(err => {
            console.log('network error: ' + err)
            this.errorMessage = err
          })
    },
    welInteresse(werk) {
      this.werkOrder = werk
    },
    geenInteresse(werk) {
      let werkId = werk.werkId
      let interesse = 0

      axios({url: 'reactie', data: {werkId, interesse}, method: 'POST'})
          .then(resp => {
            if (resp.data.result && resp.data.result === 'ok') {
              this.reactieVerzonden()
            } else {
              this.errorMessage = resp.data.error
            }
          })
          .catch(err => {
            this.errorMessage = err
          })
    },
    wijzigReactie(werk) {
      let werkId = werk.werkId

      axios({url: 'reactie', data: {werkId}, method: 'POST'})
          .then(resp => {
            if (resp.data.result && resp.data.result === 'ok') {
              this.reactieVerzonden()
            } else {
              this.errorMessage = resp.data.error
            }
          })
          .catch(err => {
            this.errorMessage = err
          })
    },
    reactieVerzonden() {
      this.updateContent()
      this.werkOrder = null
    },
  }
}
</script>

<style scoped>

</style>