<template>
  <v-app>
    <v-app-bar
        app
        color="primary"
        dark
    >
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <div class="d-flex align-center">
        Van der Vlugt - werk
      </div>
    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
    >
      <v-layout column fill-height justify-space-between>
        <v-list
            dense
            nav
        >
          <v-list-item-group
              active-class="blue--text text--accent-4"
          >
            <v-list-item
                to="/"
            >
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Werk</v-list-item-title>
            </v-list-item>

            <v-list-item
                v-if="isLoggedIn"
                @click="logout"
            >
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Uitloggen</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <div class="text-center text-subtitle-2">{{ buildTimestamp }}</div>
      </v-layout>
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    drawer: false,
  }),
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    },
    authStatus: function () {
      return this.$store.getters.authStatus
    },
    buildTimestamp: function () {
      return 'v' + document.documentElement.dataset.buildTimestampUtc.substr(0, 16);
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/inloggen')
          })
    }
  },
};
</script>
