import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        status: '',
        tokenCreated: localStorage.getItem('tokenCreated') || '',
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, tokenCreated) {
            state.status = 'success'
            state.tokenCreated = tokenCreated
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.tokenCreated = ''
        },
    },
    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')

                axios({url: 'auth', data: user, method: 'POST'})
                    .then(resp => {
                        if (resp.data.token) {
                            const now = new Date()
                            const tokenCreated = now.getTime()
                            localStorage.setItem('tokenCreated', tokenCreated)
                            commit('auth_success', tokenCreated)
                        } else {
                            commit('auth_error')
                            reject(resp.data.error)
                        }
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('tokenCreated')
                        reject(err)
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve /*, reject */) => {
                commit('logout')
                localStorage.removeItem('tokenCreated')
                resolve()
            })
        },
    },
    modules: {},
    getters: {
        isLoggedIn: state => !!state.tokenCreated,
        authStatus: state => state.status,
    }
})