<template>
  <v-row
      align="center"
      justify="center"
  >
    <v-col
        cols="12"
        md="6"
        sm="8"
    >
      <v-alert v-if="resultaat" type="success">
        {{ resultaat }}
      </v-alert>
      <v-card v-if="!resultaat" class="elevation-12">
        <v-toolbar
            color="primary"
            dark
            flat
        >
          <v-toolbar-title>Opvragen wachtwoord</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="login">
            <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                prepend-icon="mdi-account"
            ></v-text-field>
          </v-form>
          <v-alert v-if="errorMessage" dense text type="error">
            {{ errorMessage }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              :disabled="!valid"
              color="primary"
              @click="opvragen"
          >
            Opvragen wachtwoord
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "RequestPasswordForm",
  data() {
    return {
      email: "",
      emailRules: [
        v => !!v || 'E-mail is verplicht',
        v => /.+@.+\..+/.test(v) || 'Geen geldig e-mailadres',
      ],
      errorMessage: null,
      valid: false,
      resultaat: null
    }
  },
  methods: {
    opvragen: function () {
      let email = this.email
      axios({url: 'send-reset-link', data: {email}, method: 'POST'})
          .then(resp => {
            if (resp.data.result) {
              this.resultaat = resp.data.result
            } else {
              this.errorMessage = resp.data.error
              setTimeout(() => {
                this.errorMessage = ''
              }, 3000)
            }
          })
          .catch(err => {
            console.log('network error: ' + err)
            this.errorMessage = err
            setTimeout(() => {
              this.errorMessage = ''
            }, 3000)
          })
    }
  },
}
</script>

<style scoped>

</style>