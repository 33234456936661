<template>
  <v-row
      align="center"
      justify="center"
  >
    <v-col
        cols="12"
        md="8"
        sm="8"
    >
      <v-card class="elevation-12">
        <v-toolbar
            color="primary"
            dark
            flat
        >
          <v-toolbar-title>Verstuur reactie</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="login">
            <v-text-field
                v-model="tarief"
                :rules="tariefRules"
                label="Tarief per uur"
                prepend-icon="mdi-currency-eur"
            ></v-text-field>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                min-width="290px"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="startdatum"
                    v-bind="attrs"
                    v-on="on"
                    :rules="startdatumRules"
                    label="Startdatum"
                    prepend-icon="mdi-calendar"
                    readonly
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date"
                  :first-day-of-week="1"
                  :showWeek="1"
                  no-title
                  scrollable
                  @input="$refs.menu.save(date);menu = false"
              >
              </v-date-picker>
            </v-menu>
            <v-text-field
                v-model="opmerkingen"
                label="Opmerkingen"
                prepend-icon="mdi-text"
            ></v-text-field>
          </v-form>
          <v-alert v-if="errorMessage" dense text type="error">
            {{ errorMessage }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="default"
              @click="terug"
          >
            Terug
          </v-btn>
          <v-btn
              :disabled="!valid"
              color="primary"
              @click="verstuurReactie"
          >
            Verstuur reactie
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="mt-6">
        <v-card-title>{{ this.werkOrder.plaats }}: {{ this.werkOrder.omschrijving }}</v-card-title>
        <v-card-subtitle>Montage uren: {{ this.werkOrder.montageUren }}. Starten in periode
          {{ this.werkOrder.startenVanaf }} t/m
          {{ this.werkOrder.startenUiterlijk }}
        </v-card-subtitle>
        <v-card-text>
          Order: {{ this.werkOrder.ordernr }}
          <span v-if="this.werkOrder.bijzonderheden"><br>Bijzonderheden: {{ this.werkOrder.bijzonderheden }}</span>
        </v-card-text>
      </v-card>
    </v-col>

  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "ReactieForm",
  props: ['werkOrder'],
  data() {
    return {
      date: this.parseDate(this.werkOrder.reactieStartdatum || this.werkOrder.startenVanaf),
      menu: false,
      tarief: this.werkOrder.reactieTarief,
      tariefRules: [
        v => !!v || 'Tarief is verplicht',
        v => /^[1-9]\d?(|[.,]\d\d)$/.test(v) || 'Geen geldig tarief',
      ],
      startdatum: this.werkOrder.reactieStartdatum || this.werkOrder.startenVanaf,
      opmerkingen: "",
      startdatumRules: [
        v => !!v || 'Startdatum is verplicht',
      ],
      errorMessage: null,
      valid: false,
    }
  },

  watch: {
    date() {
      this.startdatum = this.formatDate(this.date)
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}-${month}-${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    terug: function () {
      this.$parent.werkOrder = null;
    },
    verstuurReactie: function () {
      let werkId = this.werkOrder.werkId
      let tarief = this.tarief
      let startdatum = this.date
      let opmerkingen = this.opmerkingen
      let interesse = 1

      axios({url: 'reactie', data: {werkId, tarief, startdatum, opmerkingen, interesse}, method: 'POST'})
          .then(resp => {
            if (resp.data.result && resp.data.result === 'ok') {
              this.reactieVerzonden()
            } else {
              this.errorMessage = resp.data.error
            }
          })
          .catch(err => {
            this.errorMessage = err
          })
    },
    reactieVerzonden: function () {
      this.$parent.reactieVerzonden()
    },
  },
}
</script>

<style scoped>

</style>