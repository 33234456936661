<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <RequestPasswordForm v-if="!hashReceived"/>
        <div v-if="hashReceived">
          <div v-if="resultaat">
            <v-alert type="success">
              {{ resultaat }}
            </v-alert>
            <v-btn to="/">Inloggen</v-btn>
          </div>
          <div v-if="errorMessage">
            <v-alert type="error">
              {{ errorMessage }}
            </v-alert>
            <v-btn @click="opnieuwOpvragenWachtwoord">Opvragen wachtwoord</v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RequestPasswordForm from "@/components/RequestPasswordForm";
import axios from "axios";

export default {
  name: "Login",

  components: {
    RequestPasswordForm,
  },

  data() {
    return {
      hashReceived: false,
      errorMessage: '',
      resultaat: '',
    }
  },

  created() {
    if (this.$route.query.hash) {
      this.hashReceived = true;
      const hash = this.$route.query.hash
      axios({url: 'reset-password', data: {hash}, method: 'POST'})
          .then(resp => {
            if (resp.data.result) {
              this.resultaat = resp.data.result
            } else {
              this.errorMessage = resp.data.error
            }
          })
          .catch(err => {
            console.log('network error: ' + err)
            this.errorMessage = err
          })
    }
  },
  methods: {
    opnieuwOpvragenWachtwoord: function () {
      this.hashReceived = false;
      this.errorMessage = '';
      this.resultaat = '';
      this.$router.push('/opvragenWachtwoord')
    }
  }
}
</script>

<style scoped>

</style>